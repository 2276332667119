import React from 'react';

import {
  ActionsRow,
  Amount,
  AmountIcon,
  ButtonComp,
  CardColumn,
  CardRow,
  CardRowForSvg,
  Contact,
  ContactSvgRow,
  Content,
  CpfIcon,
  Description,
  EditIcon,
  HeaderRow,
  InfoArea,
  InfoSvgRow,
  ModalValue,
  KeyWord,
  KeyWordsArea,
  MailIcon,
  Modal,
  ModalSelect,
  ModalSelectContent,
  MoneyIcon,
  Name,
  PinIcon,
  RowTitle,
  Salary,
  Select,
  SelectName,
  TrashIcon,
  UserImg,
  AdvisorImg,
  UserArea,
  UserInfoArea,
  UserColumnArea,
  MatchIcon,
  Matchs,
  DocumentArea,
  DocumentIcon,
  OccupationIcon,
  ArrowIcon,
  ContactArea,
  ContactCard,
  ContactData,
  ContactDescrition,
  ContactContent,
  Occupation,
  SeeMoreLink,
  LoadRow,
  LoadingIndicator,
} from './styles';

import typ from './index.d';
import { toast } from 'react-toastify';
import { formatCPF, transformarData } from '../../../utils/convert';
import { useNavigate } from 'react-router-dom';
import { PersonalClientCRUD } from '../../../services/requests/personal-client';
import { PersonalClientDataModalView } from '../../../services/requests/personal-client/index.d';
import {
  CONST_PERSONAL_CLIENT_KAMBAM_STEP,
  CONST_STATUS,
} from '../../../utils/CONSTANTS';
import { ClientHistoryPropsData } from '../../../services/requests/client_history/index.d';
import { ClientHistoryCRUD } from '../../../services/requests/client_history';
import Button from '../../button';
import ClientHistoryModal from '../client-history';
import { theme } from '../../../global/styles';

const personalClientCrud = new PersonalClientCRUD();
const clientHistoryCrud = new ClientHistoryCRUD();

const PersonalClientModal: React.FC<typ.Props> = ({
  onClose,
  personalClient,
  showPdfCallback,
  refreshData
}) => {
  // Navigation
  const navigate = useNavigate();


  // States
  const [client, setClient] = React.useState<PersonalClientDataModalView>();
  const [showKambamModal, setShowKambamModal] = React.useState(false);
  const [showStatusModal, setShowStatusModal] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [loadingHistory, setLoadingHistory] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const [kambamStep, setKambamStep] = React.useState<{
    id: number;
    color: string;
    title: string;
  }>(KAMBAM_STEPS[0]);
  const [status, setStatus] = React.useState<{
    id: number;
    color: string;
    title: string;
    textColor?: string;
  }>(STATUS[0]);
  const [clientHistory, setClientHistory] =
    React.useState<ClientHistoryPropsData[]>();


  // Callbacks
  const updatePersonalClient = React.useCallback(
    async (status?: string, kambamStep?: string) => {
      let currentStatus: string = '';
      let currentKambam: string = '';

      if (kambamStep) {
        if (
          CONST_PERSONAL_CLIENT_KAMBAM_STEP.findIndex(
            item => item.label.toLowerCase() === kambamStep.toLocaleLowerCase(),
          ) >= 0
        ) {
          currentKambam =
            CONST_PERSONAL_CLIENT_KAMBAM_STEP[
              CONST_PERSONAL_CLIENT_KAMBAM_STEP.findIndex(
                item =>
                  item.label.toLowerCase() === kambamStep.toLocaleLowerCase(),
              )
            ].keys;
        } else {
          currentKambam = kambamStep;
        }
      }

      if (status) {
        if (
          CONST_STATUS.findIndex(
            item => item.label.toLowerCase() === status.toLocaleLowerCase(),
          ) >= 0
        ) {
          currentStatus =
            CONST_STATUS[
              CONST_STATUS.findIndex(
                item => item.label.toLowerCase() === status.toLocaleLowerCase(),
              )
            ].keys;
        } else {
          currentStatus = status;
        }
      }

      const dataApi: any = {};
      if (currentKambam) dataApi.kanban_step = currentKambam;
      if (currentStatus) dataApi.status = currentStatus;

      dataApi.id = personalClient;

      try {
        const {
          data,
          status: Status,
          error,
        } = await personalClientCrud.update(dataApi);

        if (error) throw new Error(error.message.description);
        if (Status === 200 && data) {
          toast('Cliente editado com sucesso.');
        }
      } catch (e) {
        alert(e);
      }
    },
    [],
  );

  const deletePersonalClient = React.useCallback(async (id: number) => {
    try {
      const { data, status, error } = await personalClientCrud.delete({ id });

      if (error) throw new Error('Erro ao deletar usuario.');

      if (status === 200) {
        toast('Cliente deletado com sucesso.');
        // refreshData();
        onClose();
      }
    } catch (e) {
      toast(e as String);
    }
  }, []);

  const getClient = React.useCallback(async (page?: number) => {
    setLoading(true)
    try {
      const { data, status, error } = await personalClientCrud.modalView({
        id: personalClient || 0,
      });

      if (error) {
        throw new Error(error.message.description);
      }

      if (status === 200 && data) {
        setClient(data.PersonalClient[0]);

        let currentKambam: any = {};
        let currentStatus: any = {};

        if (
          KAMBAM_STEPS.findIndex(
            item =>
              item.title.toLowerCase() ===
              data.PersonalClient[0].kanban_step.toLocaleLowerCase(),
          ) >= 0
        ) {
          currentKambam =
            KAMBAM_STEPS[
              KAMBAM_STEPS.findIndex(
                item =>
                  item.title.toLowerCase() ===
                  data.PersonalClient[0].kanban_step.toLocaleLowerCase(),
              )
            ];
        }
        if (
          STATUS.findIndex(
            item =>
              item.title.toLowerCase() ===
              data.PersonalClient[0].status.toLocaleLowerCase(),
          ) >= 0
        ) {
          currentStatus =
            STATUS[
              STATUS.findIndex(
                item =>
                  item.title.toLowerCase() ===
                  data.PersonalClient[0].status.toLocaleLowerCase(),
              )
            ];
        }

        setKambamStep(currentKambam);
        setStatus(currentStatus);
      }
    } catch (e) {
      alert(e);
    } finally {
      setLoading(false)
    }
  }, []);

  const getClientHistory = React.useCallback(async (page?: number) => {
    setLoadingHistory(true)
    try {
      const { data, status, error } = await clientHistoryCrud.read({
        personal_client_id: Number(personalClient),
        page,
        per_page: 20,
      });

      if (error) {
        throw new Error(error.message.description);
      }

      if (status === 200 && data) {
        setClientHistory(data.ClientHistory);
      }
    } catch (e) {
      alert(e);
    } finally {
      setLoadingHistory(false)
    }
  }, []);

  React.useEffect(() => {
    getClient();
    getClientHistory();
  }, []);

  return (
    <Modal modalTitle="Cliente" onClose={onClose} hideButton maxWidth={687} customStyle={{minHeight: 684, justifyContent: 'flex-start'}}>
      {!loading && (
        <>
          <HeaderRow>
            <ModalSelectContent>
              <ModalValue
                background={kambamStep?.color}
                onClick={() => {
                  setShowKambamModal(true);
                }}>
                {kambamStep?.title} {'| >'}
              </ModalValue>
              {showKambamModal && (
                <ModalSelect>
                  {KAMBAM_STEPS?.map(el => (
                    <Select
                      onClick={() => {
                        // editContact(el.id, e.id);
                        setKambamStep(el);
                        updatePersonalClient(undefined, el.title);
                        setShowKambamModal(false);
                      }}>
                      <SelectName key={el.id}>{el.title}</SelectName>
                    </Select>
                  ))}
                </ModalSelect>
              )}
            </ModalSelectContent>
            <ActionsRow>
              <ButtonComp
                onClick={() =>
                  navigate(`/editar-cliente-recolocacao/${personalClient}`)
                }>
                <EditIcon />
              </ButtonComp>
              <ButtonComp
                onClick={() => {
                  deletePersonalClient(personalClient || 0);
                }}>
                <TrashIcon />
              </ButtonComp>
            </ActionsRow>
          </HeaderRow>
          <HeaderRow>
            <ModalSelectContent>
              <ModalValue
                background={status?.color}
                onClick={() => {
                  setShowStatusModal(true);
                }}
                textColor={status.textColor}>
                {status?.title}
                <ArrowIcon style={{ color: status.textColor }} />
              </ModalValue>
              {showStatusModal && (
                <ModalSelect>
                  {STATUS?.map(el => (
                    <Select
                      onClick={() => {
                        // editContact(el.id, e.id);
                        setStatus(el);
                        updatePersonalClient(el.title, undefined);
                        setShowStatusModal(false);
                      }}>
                      <SelectName key={el.id}>{el.title}</SelectName>
                    </Select>
                  ))}
                </ModalSelect>
              )}
            </ModalSelectContent>
            <AdvisorImg
              src={
                client?.advisor_relation?.image ||
                require('../../../assets/temp/temp-user.png')
              }
            />
          </HeaderRow>
        </>
      )}

      <Content>
        {loading ? 
          (
            <LoadRow>
              <LoadingIndicator
                customStyle={{
                  width: 40,
                  height: 40,
                  color: theme.colors.secondary,
                  marginBottom: 12,
                }}
                size="large"
              />
            </LoadRow> 
          ) 
          :
            <>
              <InfoArea>
                <UserArea>
                  <UserImg src={client?.image || require('../../../assets/temp/temp-user.png')} />
                  <UserInfoArea>
                    <UserColumnArea>
                      <Name>{client?.name}</Name>
                      <Occupation>{client?.occupation}</Occupation>
                    </UserColumnArea>
                    <CardRow>
                      <UserColumnArea>
                        <InfoSvgRow style={{ marginBottom: 4 }}>
                          <MatchIcon />
                          <Matchs>{client?.vacancy_matching_quantity} matchs</Matchs>
                        </InfoSvgRow>
                        {client?.budget && (
                          <InfoSvgRow style={{ marginBottom: 0 }}>
                            <MoneyIcon />
                            <Salary>R$ {client?.budget}</Salary>
                          </InfoSvgRow>
                        )}
                      </UserColumnArea>
                      <DocumentArea onClick={showPdfCallback}>
                        <DocumentIcon />
                      </DocumentArea>
                    </CardRow>
                  </UserInfoArea>
                </UserArea>
                <InfoSvgRow>
                  <RowTitle>Dados pessoais </RowTitle>
                  <SeeMoreLink
                    href={`/visualizar-cliente-recolocacao/${personalClient}`}>
                    Ver mais
                  </SeeMoreLink>
                </InfoSvgRow>
                <ContactSvgRow>
                  <MailIcon />
                  <Contact>{client?.email}</Contact>
                </ContactSvgRow>
                {client?.local && (
                  <ContactSvgRow>
                    <PinIcon />
                    <Contact>{client.local}</Contact>
                  </ContactSvgRow>
                )}
                {client?.cpf && (
                  <ContactSvgRow>
                    <CpfIcon />
                    <Contact>{formatCPF(client.cpf)}</Contact>
                  </ContactSvgRow>
                )}

                <InfoSvgRow>
                  <RowTitle>Dados profissonais</RowTitle>
                  <SeeMoreLink
                    href={`/visualizar-cliente-recolocacao/${personalClient}`}>
                    Ver mais
                  </SeeMoreLink>
                </InfoSvgRow>
                {client?.key_words && client?.key_words.length > 0 && (
                  <KeyWordsArea>
                    {client.key_words.map((e, i) => (
                      <KeyWord key={i}>{e}</KeyWord>
                    ))}
                  </KeyWordsArea>
                )}
              </InfoArea>
              <ContactArea>
                <ContactContent>
                  {loadingHistory 
                    ?
                      (
                        <LoadRow>
                          <LoadingIndicator
                            customStyle={{
                              width: 40,
                              height: 40,
                              color: theme.colors.secondary,
                              marginBottom: 12,
                            }}
                            size="large"
                          />
                        </LoadRow> 

                      )
                    : 
                    <>
                      {clientHistory?.map(e => (
                        <ContactCard key={e.created_at}>
                          <ContactData>{transformarData(e.date)}</ContactData>
                          <ContactDescrition>{e.description}</ContactDescrition>
                        </ContactCard>
                      ))}
                    </>
                  }
                </ContactContent>
                <Button
                  title="Adicionar Evento"
                  customStyle={{ minWidth: 0 }}
                  callback={() => setShowModal(true)}
                />
              </ContactArea> 
            </>
          }
      </Content>

      { // Modal Criação de historico
        showModal && (
        <ClientHistoryModal
          personalClientId={personalClient || 0}
          onClose={() => {
            setShowModal(false);
            getClientHistory();
          }}
        />
      )}
    </Modal>
  );
};

export default PersonalClientModal;

const KAMBAM_STEPS = [
  {
    id: 0,
    color: '#7f2c7c',
    title: 'Cadastro',
  },
  {
    id: 1,
    color: '#4a94f8',
    title: 'Retrospectiva de carreira',
  },
  {
    id: 2,
    color: '#56c991',
    title: 'Aprovar currículo',
  },
  {
    id: 3,
    color: '#ff6262',
    title: 'Formulário de autoanálise',
  },
  {
    id: 4,
    color: '#b55bcc',
    title: 'Discussão de autoanálise',
  },
  {
    id: 5,
    color: '#8075ff',
    title: 'Entrevista simulada',
  },
  {
    id: 6,
    color: '#bfb060',
    title: 'Dinâmica simulada',
  },
  {
    id: 7,
    color: '#ff72f1',
    title: 'Workshop linkedin',
  },
  {
    id: 8,
    color: '#acbb20',
    title: 'Prospecção',
  },
  {
    id: 9,
    color: '#56c991',
    title: 'Entrevista',
  },
  {
    id: 10,
    color: '#0900ae',
    title: 'Finalizado',
  },
];
const STATUS = [
  {
    id: 0,
    color: '#FF565685',
    textColor: '#7C0A0A',
    title: 'Atuando/Trabalhando',
  },
  {
    id: 1,
    color: '#ABDBCA',
    textColor: '#316F59',
    title: 'Disponível',
  },
];
