import Cookies from 'js-cookie';
import { axiosManager } from '../../axios-manager';
import PersonalClientCRUDProps from './index.d';

class PersonalClientCRUD {
  private readonly token;

  constructor() {
    this.token = Cookies.get('token');
  }

  create({
    advisor_id,
    backup_phone,
    birth_date,
    budget,
    client_type,
    cpf,
    email,
    kanban_step,
    key_words,
    local,
    marital_status,
    name,
    number_children,
    occupation,
    personal_phone,
    sexual_gender,
    status,
    attachment,
    image,
    moving_conditions,
    replacement_company,
    replacement_office,
    replacement_salary,
    replacement_start_date,
    end_of_contract, 
    start_of_contract
  }: PersonalClientCRUDProps.createParams) {
    const data = new FormData();
    data.append('name', name);
    data.append('email', email);
    data.append('local', local);
    data.append('budget', budget);
    data.append('cpf', cpf);
    data.append('personal_phone', personal_phone);
    data.append('backup_phone', personal_phone);
    data.append('birth_date', '1988-02-14');
    data.append('occupation', occupation);
    data.append('sexual_gender', sexual_gender);
    data.append('status', status);
    data.append('client_type', client_type);
    data.append('number_children', number_children.toString());
    data.append('kanban_step', kanban_step);
    data.append('marital_status', marital_status);

    data.append('advisor_id', advisor_id.toString());
    if(key_words)data.append('key_words', JSON.stringify(key_words));
    if(image) data.append('image', image);

    if(birth_date)data.append('birth_date', birth_date);
    if(moving_conditions)data.append('moving_conditions', moving_conditions);
    if(replacement_company)data.append('replacement_company', replacement_company);
    if(replacement_office)data.append('replacement_office', replacement_office);
    if(replacement_salary)data.append('replacement_salary', replacement_salary);
    if(replacement_start_date) data.append('replacement_start_date', replacement_start_date);

    if(attachment) data.append('attachment', attachment);
    if(start_of_contract) data.append('start_of_contract', start_of_contract);
    if(end_of_contract) data.append('end_of_contract', end_of_contract);
    return axiosManager<PersonalClientCRUDProps.createReturn>({
      method: 'post',
      url: `/personal-client/create`,
      auth: this.token || '',
      payload: data,
    });
  }

  read({ page = 1, per_page = 10, name}: PersonalClientCRUDProps.readParams) {
    let url = `personal-client/list?page=${page}&per_page=${per_page}`;
    if(name) url = url+`&name=${name}`
    return axiosManager<PersonalClientCRUDProps.readReturn>({
      method: 'get',
      url,
      auth: this.token || '',
      payload: null,
    });
  }

  update({
    backup_phone,
    birth_date,
    budget,
    client_type,
    cpf,
    email,
    kanban_step,
    key_words,
    local,
    marital_status,
    name,
    number_children,
    occupation,
    personal_phone,
    sexual_gender,
    status,
    id,
    moving_conditions,
    replacement_company,
    replacement_office,
    replacement_start_date,
    replacement_salary,
    advisor_id, 
    attachment,
    image,
    end_of_contract,
    start_of_contract
  }: PersonalClientCRUDProps.updateParams) {
    const data = new FormData();
    
    if(name) data.append('name', name);
    if(email) data.append('email', email);
    if(local) data.append('local', local);
    if(budget) data.append('budget', budget);
    if(cpf) data.append('cpf', cpf);
    if(personal_phone) data.append('personal_phone', personal_phone);
    if(personal_phone) data.append('backup_phone', personal_phone);
    if(birth_date) data.append('birth_date', '1988-02-14');
    if(occupation) data.append('occupation', occupation);
    if(sexual_gender) data.append('sexual_gender', sexual_gender);
    if(status) data.append('status', status);
    if(client_type) data.append('client_type', client_type);
    if(number_children) data.append('number_children', number_children.toString());
    if(kanban_step) data.append('kanban_step', kanban_step);
    if(marital_status) data.append('marital_status', marital_status);
    if(birth_date)data.append('birth_date', birth_date);

    if(key_words)data.append('key_words', JSON.stringify(key_words));
    
    if(image) data.append('image', image);
    if(attachment) data.append('attachment', attachment);
    
    if(moving_conditions)data.append('moving_conditions', moving_conditions);
    if(replacement_company)data.append('replacement_company', replacement_company);
    if(replacement_office)data.append('replacement_office', replacement_office);
    if(replacement_salary)data.append('replacement_salary', replacement_salary);
    if(replacement_start_date) data.append('replacement_start_date', replacement_start_date);
    
    if(advisor_id) data.append('advisor_id', advisor_id.toString());

    if(start_of_contract) data.append('start_of_contract', start_of_contract);
    if(end_of_contract) data.append('end_of_contract', end_of_contract);

    return axiosManager<PersonalClientCRUDProps.updateReturn>({
      method: 'put',
      url: `/personal-client/update/${id}`,
      auth: this.token || '',
      payload: data
    });
  }

  modalView({ id, name }: PersonalClientCRUDProps.modalViewParams) {
    let url = `/personal-client/view/modal`;
    if (id) url = url + `?id=${id}`;
    if (name) url = url + `?name=${name}`;

    return axiosManager<PersonalClientCRUDProps.modalViewReturn>({
      method: 'get',
      url,
      auth: this.token || '',
      payload: null,
    });
  }

  view({ id }: PersonalClientCRUDProps.viewParams) {
    let url = `/personal-client/view`;
    if (id) url = url + `?id=${id}`;

    return axiosManager<PersonalClientCRUDProps.viewReturn>({
      method: 'get',
      url,
      auth: this.token || '',
      payload: null,
    });
  }

  delete({ id }: PersonalClientCRUDProps.deleteParams) {
    return axiosManager<PersonalClientCRUDProps.deleteReturn>({
      method: 'delete',
      url: `/personal-client/delete/${id}`,
      auth: this.token || '',
      payload: null,
    });
  }

  // keyWords(key_words: string[], type: 'create' | 'update', id?: number ) {

  //   return axiosManager<PersonalClientCRUDProps.createReturn>({
  //     method: 'post',
  //     url: `/personal-client/${type}/${id}`,
  //     auth: this.token || '',
  //     payload: {
  //       key_words
  //     },
  //   });
  // }
}

export { PersonalClientCRUD };
