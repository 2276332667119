import React, { LegacyRef } from 'react';

import typ from './index.d';
import { Modal, Separator } from './styles';
import InputComponent from '../../input';
import { toast } from 'react-toastify';
import { ContactHistoryCRUD } from '../../../services/requests/contact-history';
import InputSelectComponent from '../../input-select';
import { CONST_INDUSTRIES, CONST_LEVEL, CONST_SERVICES_TYPE } from '../../../utils/CONSTANTS';
import { ProfissionalExperienceCRUD } from '../../../services/requests/profissional-experience';

const ProfExcrud = new ProfissionalExperienceCRUD();

const ProfissionalExperienceModal: React.FC<typ.Props> = ({
  onClose,
  data,
  id
}) => {
  // States
  const [company, setCompany] = React.useState(data?.company_name || '');
  const [role, setRole] = React.useState(data?.role || '');
  const [occupation, setOccupation] = React.useState(
    data?.occupation_area || '',
  );
  const [level, setLevel] = React.useState(
    data?.level || '',
  );
  const [period, setPeriod] = React.useState(
    data?.period || '',
  );

  const [warning, setWarning] = React.useState({
    occupation: false,
    level: false,
  });

  const createProfEx = React.useCallback(
    async (company: string, role: string, occupation: string, level: string, period: string, id: number) => {
      try {
        if (!occupation && !level) {
          setWarning(e => ({ ...e, occupation: true }));
          toast('Selecione os campos indicados');
          return;
        }
       
        if (!occupation) {
          setWarning(e => ({ ...e, occupation: true }));
          toast('Selecione a área de ocupação.');
          return;
        }
        if (!level) {
          setWarning(e => ({ ...e, level: true }));
          toast('Selecione o nível.');
          return;
        }

        const { data, status, error } = await ProfExcrud.create({
         company_name: company,
         level,
         occupation_area: occupation,
         period,
         role,
         personal_client_id: id
        });

        if (error) throw new Error(error.message.description);

        if (status === 200) {
          toast('Experiência profissional criada com sucesso.');
          onClose();
        }
      } catch (e) {
        alert(e);
      }
    },
    [],
  );

  const editProfEx = React.useCallback(
    async (company: string, role: string, occupation: string, level: string, period: string, id: number) => {
      try {
        if (!occupation && !level) {
          setWarning(e => ({ ...e, occupation: true }));
          toast('Selecione os campos indicados');
          return;
        }
       
        if (!occupation) {
          setWarning(e => ({ ...e, occupation: true }));
          toast('Selecione a área de ocupação.');
          return;
        }
        if (!level) {
          setWarning(e => ({ ...e, level: true }));
          toast('Selecione o nível.');
          return;
        }

        let currentOccupation: string;
        let currentLevel: string;

        if (
          CONST_INDUSTRIES.findIndex(
            item => item.label.toLowerCase() === occupation.toLocaleLowerCase(),
          ) >= 0
        ) {
          currentOccupation =
            CONST_INDUSTRIES[
              CONST_INDUSTRIES.findIndex(
                item => item.label.toLowerCase() === occupation.toLocaleLowerCase(),
              )
            ].keys;
        } else {
          currentOccupation = occupation;
        }
        if (
          CONST_LEVEL.findIndex(
            item => item.label.toLowerCase() === level.toLocaleLowerCase(),
          ) >= 0
        ) {
          currentLevel =
            CONST_LEVEL[
              CONST_LEVEL.findIndex(
                item => item.label.toLowerCase() === level.toLocaleLowerCase(),
              )
            ].keys;
        } else {
          currentLevel = level;
        }

        const { data: dataReturn, status, error } = await ProfExcrud.update({
          company_name: company,
          level: currentLevel,
          occupation_area: currentOccupation,
          period,
          role,
          id: data?.id || 0
        });

        if (error) throw new Error(error.message.description);

        if (status === 200) {
          toast('Experiência profissional editada com sucesso.');
          onClose();
        }
      } catch (e) {
        alert(e);
      }
    },
    [],
  );

  return (
    <Modal
      modalTitle={`${data ? 'Editar' : 'Novo'} cliente`}
      onClose={onClose}
      buttonCallback={() => {
        if (data) {
          editProfEx(company, role, occupation, level, period, id);
          return;
        }
        createProfEx(company, role, occupation, level, period, id);
      }}
      buttonTitle={`${data ? 'Editar' : 'Enviar'}`}>

      <InputComponent
        placeholder="Nome da empresa"
        type="text"
        value={company}
        setValue={setCompany}
      />

      <Separator />
      
      <InputComponent
        placeholder="Papel no trabalho"
        type="text"
        value={role}
        setValue={setRole}
      />

      <Separator />

      <InputSelectComponent
        customContainerSize="full"
        value={occupation}
        setValue={setOccupation}
        placeholder={'Área de atuação'}
        selectData={CONST_INDUSTRIES}
        customStyle={{ background: '#fff' }}
        warning={warning.occupation}
      />

      <Separator />

      <InputSelectComponent
        customContainerSize="full"
        value={level}
        setValue={setLevel}
        placeholder={'Nível do cargo'}
        selectData={CONST_LEVEL}
        customStyle={{ background: '#fff' }}
        warning={warning.level}
      />

      <Separator />

      <InputComponent
        customContainerSize="full"
        value={period}
        setValue={setPeriod}
        placeholder={'Período'}
      />
    </Modal>
  );
};

export default ProfissionalExperienceModal;
