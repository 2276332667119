import React from 'react';
import { Container } from './styles';

import typ from './index.d' 
import Load from '../load';
const Button :React.FC<typ.Props> = ({title, callback, customStyle, iconRight: IconRight, isLoading}) => {
   return (
      <Container onClick={callback} style={customStyle} disabled={isLoading}>
         {IconRight && <IconRight />}
         {title}
      </Container>
   );
}

export default Button ;