import Cookies from 'js-cookie';
import { axiosManager } from '../../axios-manager';
import ProfissionalExperienceCRUDProps from './index.d';

class ProfissionalExperienceCRUD {
  private readonly token;

  constructor() {
    this.token = Cookies.get('token') || '';
  }
  create({
    company_name,
    level,
    occupation_area,
    period,
    role,
    personal_client_id
  }: ProfissionalExperienceCRUDProps.createParams) {
    return axiosManager<ProfissionalExperienceCRUDProps.createReturn>({
      method: 'post',
      url: `/professional-experience/create`,
      auth: this.token,
      payload: {
        company_name,
        level,
        occupation_area,
        period,
        role,
        personal_client_id
      },
    });
  }

  read({ page = 1, per_page = 10, personal_client_id }: ProfissionalExperienceCRUDProps.readParams) {
    let url = `/professional-experience/list?page=${page}&per_page=${per_page}`;
    if(personal_client_id)  url = url + `&personal_client_id=${personal_client_id}`;
    
    return axiosManager<ProfissionalExperienceCRUDProps.readReturn>({
      method: 'get',
      url,
      auth: this.token,
      payload: null,
    });
  }

  update({
    company_name,
    level,
    occupation_area,
    period,
    role,
    id
  }: ProfissionalExperienceCRUDProps.updateParams) {
    return axiosManager<ProfissionalExperienceCRUDProps.updateReturn>({
      method: 'put',
      url: `/professional-experience/update/${id}`,
      auth: this.token,
      payload: {
        company_name,
        level,
        occupation_area,
        period,
        role,
      },
    });
  }

  delete({ id }: ProfissionalExperienceCRUDProps.deleteParams) {
    return axiosManager<ProfissionalExperienceCRUDProps.deleteReturn>({
      method: 'delete',
      url: `/professional-experience/delete/${id}`,
      auth: this.token,
      payload: null,
    });
  }
}

export { ProfissionalExperienceCRUD };
