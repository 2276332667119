import React from 'react';
import {
  AddIcon,
  BackFullIcon,
  ButtonAddKeyWord,
  ButtonAddVancacie,
  ButtonPdf,
  ButtonRow,
  CloseIcon,
  Container,
  Content,
  DocumentInput,
  FileName,
  FilesArea,
  FilesContent,
  Header,
  HeaderBack,
  HeaderInfo,
  HeaderTitle,
  Image,
  ImageArea,
  ImageInput,
  InputContainer,
  InputsContactRow,
  InputsVancacie,
  InputsVancacieRow,
  KeyWord,
  KeyWordsArea,
  LoadingIndicator,
  LoadRow,
  PhotoIcon,
  SectionTitle,
  VacanciesContainer,
} from './styles';
import MenuBar from '../../../../components/menu-bar';
import ResponsiveContainer from '../../../../components/container';
import InputSelectComponent from '../../../../components/input-select';
import {
  CONST_CLIENT_TYPE,
  CONST_OCCUPATION,
  CONST_PERSONAL_CLIENT_KAMBAM_STEP,
  CONST_SEXUALIDADE,
  CONST_STATUS,
} from '../../../../utils/CONSTANTS';
import EmptyImage from '../../../../assets/images/empty-image.png';
import { toast } from 'react-toastify';
import { PersonalClientCRUD } from '../../../../services/requests/personal-client';
import { convertDateToISO } from '../../../../utils/convert';
import { useParams } from 'react-router-dom';
import { theme } from '../../../../global/styles';
import { useAppSelector } from '../../../../hooks/useRedux';

const personalClientCrud = new PersonalClientCRUD();

const EditPersonalClient: React.FC = () => {
  //redux
  const user = useAppSelector(({ state }) => state.user);

  // Params
  const { id } = useParams();

  // Refs
  const inputImageRef = React.useRef<HTMLInputElement>(null);
  const inputCurriculoRef = React.useRef<HTMLInputElement>(null);

  // States
  const [name, setName] = React.useState('');
  const [mail, setMail] = React.useState('');
  const [workplace, setWorkplace] = React.useState('');
  const [budget, setBudget] = React.useState('');
  const [cpf, setCpf] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [birth, setBirth] = React.useState('');
  const [status, setStatus] = React.useState('');
  const [occupation, setOccupation] = React.useState('');
  const [sex, setSex] = React.useState('');
  const [clientType, setClientType] = React.useState('');
  const [kambamStep, setKambamStep] = React.useState('');
  const [childrens, setChildrens] = React.useState('');
  const [civilState, setCivilState] = React.useState('');
  const [keyWord, setKeyWord] = React.useState('');
  const [keyWordList, setKeyWordList] = React.useState<string[]>();

  const [replacementCompany, setReplacementCompany] = React.useState('');
  const [replacementSalary, setReplacementSalary] = React.useState('');
  const [replacementOffice, setReplacementOffice] = React.useState('');
  const [replacementDate, setReplacementDate] = React.useState('');
  const [conditionsMove, setConditionMove] = React.useState('');

  const [img, setImg] = React.useState('');
  const [imageBase64, setImageBase64] = React.useState<any>();
  const [curriculo, setCurriculo] = React.useState<File>();

  const [startContract, setStartContract] = React.useState('')
  const [endContract, setEndContract] = React.useState('')

  const [warning, setWarning] = React.useState({
    name: false,
    mail: false,
    workplace: false,
    budget: false,
    cpf: false,
    phone: false,
    birth: false,
    status: false,
    occupation: false,
    sex: false,
    clientType: false,
    kambamStep: false,
    childrens: false,
    civilState: false,
  });

  const [loading, setLoading] = React.useState(false)

  // Callbacks
  const handleUpdateVancancie = React.useCallback(
    async (
      file: any,
      image: any,
      name: string,
      mail: string,
      workplace: string,
      budget: string,
      cpf: string,
      phone: string,
      birth: string,
      status: string,
      occupation: string,
      sex: string,
      clientType: string,
      kambamStep: string,
      childrens: string,
      civilState: string,
      keyWordList: string[],
      conditionsMove: string,
      replacementDate: string,
      replacementOffice: string,
      replacementCompany: string,
      replacementSalary: string,
      startContract: string,
      endContract: string,
    ) => {
      if (
        !name &&
        !mail &&
        !cpf &&
        !occupation &&
        !budget &&
        !sex &&
        !phone &&
        !birth &&
        !status &&
        !clientType &&
        !childrens &&
        !civilState &&
        !kambamStep
      ) {
        setWarning({
          name: true,
          mail: true,
          workplace: true,
          budget: true,
          cpf: true,
          phone: true,
          birth: true,
          status: true,
          occupation: true,
          sex: true,
          clientType: true,
          kambamStep: true,
          childrens: true,
          civilState: true,
        });
        toast('Nenhum campo foi preenchido');
        return;
      }
      if (!name) {
        setWarning(e => ({ ...e, name: true }));
        toast('Preencha todos os campos indicados.');
        return;
      }
      if (!mail) {
        setWarning(e => ({ ...e, mail: true }));
        toast('Preencha todos os campos indicados.');
        return;
      }
      if (!cpf) {
        setWarning(e => ({ ...e, cpf: true }));
        toast('Preencha todos os campos indicados.');
        return;
      }
      if (!phone) {
        setWarning(e => ({ ...e, phone: true }));
        toast('Preencha todos os campos indicados.');
        return;
      }
      if (!birth) {
        setWarning(e => ({ ...e, birth: true }));
        toast('Preencha todos os campos indicados.');
        return;
      }
      if (!sex) {
        setWarning(e => ({ ...e, sex: true }));
        toast('Preencha todos os campos indicados.');
        return;
      }
      if (!civilState) {
        setWarning(e => ({ ...e, civilState: true }));
        toast('Preencha todos os campos indicados.');
        return;
      }
      if (!status) {
        setWarning(e => ({ ...e, status: true }));
        toast('Preencha todos os campos indicados.');
        return;
      }
      if (!occupation) {
        setWarning(e => ({ ...e, occupation: true }));
        toast('Preencha todos os campos indicados.');
        return;
      }
      if (!kambamStep) {
        setWarning(e => ({ ...e, kambamStep: true }));
        toast('Preencha todos os campos indicados.');
        return;
      }
      if (!budget) {
        setWarning(e => ({ ...e, budget: true }));
        toast('Preencha todos os campos indicados.');
        return;
      }
      if (!clientType) {
        setWarning(e => ({ ...e, clientType: true }));
        toast('Preencha todos os campos indicados.');
        return;
      }
      if (!mail.includes('@')) {
        toast('Digite o email corretamente');
        setWarning(e => ({ ...e, mailContact: true }));
        return;
      }

      let currentStatus: string;
      let currentOccupation: string;
      let currentKambam: string;
      let currentTypeClient: string;

      if (
        CONST_CLIENT_TYPE.findIndex(
          item => item.label.toLowerCase() === clientType.toLocaleLowerCase(),
        ) >= 0
      ) {
        currentTypeClient =
          CONST_CLIENT_TYPE[
            CONST_CLIENT_TYPE.findIndex(
              item =>
                item.label.toLowerCase() === clientType.toLocaleLowerCase(),
            )
          ].keys;
      } else {
        currentTypeClient = clientType;
      }

      if (
        CONST_PERSONAL_CLIENT_KAMBAM_STEP.findIndex(
          item => item.label.toLowerCase() === kambamStep.toLocaleLowerCase(),
        ) >= 0
      ) {
        currentKambam =
          CONST_PERSONAL_CLIENT_KAMBAM_STEP[
            CONST_PERSONAL_CLIENT_KAMBAM_STEP.findIndex(
              item =>
                item.label.toLowerCase() === kambamStep.toLocaleLowerCase(),
            )
          ].keys;
      } else {
        currentKambam = kambamStep;
      }

      if (
        CONST_STATUS.findIndex(
          item => item.label.toLowerCase() === status.toLocaleLowerCase(),
        ) >= 0
      ) {
        currentStatus =
          CONST_STATUS[
            CONST_STATUS.findIndex(
              item => item.label.toLowerCase() === status.toLocaleLowerCase(),
            )
          ].keys;
      } else {
        currentStatus = status;
      }

      if (
        CONST_OCCUPATION.findIndex(
          item => item.label.toLowerCase() === occupation.toLocaleLowerCase(),
        ) >= 0
      ) {
        currentOccupation =
          CONST_OCCUPATION[
            CONST_OCCUPATION.findIndex(
              item =>
                item.label.toLowerCase() === occupation.toLocaleLowerCase(),
            )
          ].keys;
      } else {
        currentOccupation = occupation;
      }

      try {
        const {
          data,
          status: Status,
          error,
        } = await personalClientCrud.update({
          name,
          birth_date: convertDateToISO(birth),
          budget,
          cpf: cpf.replace(/\D/g, ''),
          email: mail,
          kanban_step: currentKambam,
          key_words: keyWordList,
          local: workplace,
          marital_status: civilState,
          number_children: Number(childrens),
          occupation: currentOccupation,
          personal_phone: phone.replace(/\D/g, ''),
          sexual_gender: 'MAN',
          status: currentStatus,
          client_type: currentTypeClient,
          id: Number(id),
          moving_conditions: conditionsMove,
          replacement_company: replacementCompany,
          replacement_office: replacementOffice,
          replacement_salary: replacementSalary,
          advisor_id: Number(user?.id),
          image,
          attachment: file,
          replacement_start_date: replacementDate && convertDateToISO(replacementDate),
          start_of_contract: startContract,
          end_of_contract: endContract,
        });

        if (error) throw new Error(error.message.description);
        if (Status === 200 && data) {
          toast('Cliente editado com sucesso.');
        }
      } catch (e) {
        alert(e);
      }
    },
    [],
  );

  const getCurrentClient = React.useCallback(async () => {
    setLoading(true)
    try {
      const {
        data,
        status: Status,
        error,
      } = await personalClientCrud.view({
        id: Number(id),
      });

      if (Status === 200 && data?.PersonalClient[0]) {
        setImg(data.PersonalClient[0].image || '');
        setName(data?.PersonalClient[0].name);
        setMail(data?.PersonalClient[0].email);
        setWorkplace(data?.PersonalClient[0].local);
        setCpf(data?.PersonalClient[0].cpf);
        setBirth(
          formatDate(data?.PersonalClient[0].birth_date || '', true) || '',
        );
        setSex(data?.PersonalClient[0].sexual_gender);
        setCivilState(data?.PersonalClient[0].marital_status);
        setChildrens(data?.PersonalClient[0].number_children.toString());
        setKeyWordList(data?.PersonalClient[0].key_words);
        setBudget(data?.PersonalClient[0].budget);
        setStatus(data?.PersonalClient[0].status);
        setOccupation(data?.PersonalClient[0].occupation);
        setKambamStep(data?.PersonalClient[0].kanban_step);
        setClientType(data?.PersonalClient[0].client_type);
        setConditionMove(data?.PersonalClient[0].moving_conditions);
        setReplacementCompany(data?.PersonalClient[0].replacement_company);
        setReplacementOffice(data?.PersonalClient[0].replacement_office);
        setReplacementSalary(data?.PersonalClient[0].replacement_salary);

        setStartContract(data?.PersonalClient[0].start_of_contract)
        setEndContract(data?.PersonalClient[0].end_of_contract)
        formatPhoneNumber(data?.PersonalClient[0].personal_phone)
        setReplacementDate(formatDate(data?.PersonalClient[0].replacement_start_date, true) || '');
        

      }
    } catch (e) {}
    finally {
      setLoading(false)
    }
  }, []);

  const formatPhoneNumber = (value: string) => {
    if (value.length > 15) return;
    value = value.replace(/\D/g, '');
    value = value.replace(/^(\d{2})(\d)/g, '($1) $2');
    value = value.replace(/(\d)(\d{4})$/, '$1-$2');
    setPhone(value);
  };
  const formatCPF = (value: string) => {
    if (value.length > 14) return; // CPF formatado: 000.000.000-00
    value = value.replace(/\D/g, ''); // Remove todos os caracteres não numéricos
    value = value.replace(/(\d{3})(\d)/, '$1.$2'); // Adiciona o primeiro ponto
    value = value.replace(/(\d{3})(\d)/, '$1.$2'); // Adiciona o segundo ponto
    value = value.replace(/(\d{3})(\d{1,2})$/, '$1-$2'); // Adiciona o hífen antes dos últimos dois dígitos
    setCpf(value);
  };
  const formatDate = (value: string, inverse?: boolean) => {
    if (inverse) {
      // Divide a data original em partes
      const [year, month, day] = value.split('-');

      // Retorna a data no formato desejado
      return `${day}/${month}/${year}`;
    }
    if (value.length > 10) return; // Data formatada: DD/MM/AAAA
    value = value.replace(/\D/g, ''); // Remove todos os caracteres não numéricos
    value = value.replace(/(\d{2})(\d)/, '$1/$2'); // Adiciona a primeira barra
    value = value.replace(/(\d{2})(\d)/, '$1/$2'); // Adiciona a segunda barra
    setBirth(value);
    return;
  };
  const formatDateReplacement = (value: string) => {
    if (value.length > 10) return; // Data formatada: DD/MM/AAAA
    value = value.replace(/\D/g, ''); // Remove todos os caracteres não numéricos
    value = value.replace(/(\d{2})(\d)/, '$1/$2'); // Adiciona a primeira barra
    value = value.replace(/(\d{2})(\d)/, '$1/$2'); // Adiciona a segunda barra
    setReplacementDate(value);
  };
  const formatStartDate = (value: string) => {
    if (value.length > 10) return; // Data formatada: DD/MM/AAAA
    value = value.replace(/\D/g, ''); // Remove todos os caracteres não numéricos
    value = value.replace(/(\d{2})(\d)/, '$1/$2'); // Adiciona a primeira barra
    value = value.replace(/(\d{2})(\d)/, '$1/$2'); // Adiciona a segunda barra
    setStartContract(value);
  };
  const formatEndDate = (value: string) => {
    if (value.length > 10) return; // Data formatada: DD/MM/AAAA
    value = value.replace(/\D/g, ''); // Remove todos os caracteres não numéricos
    value = value.replace(/(\d{2})(\d)/, '$1/$2'); // Adiciona a primeira barra
    value = value.replace(/(\d{2})(\d)/, '$1/$2'); // Adiciona a segunda barra
    setEndContract(value);
  };

  // Effects
  React.useEffect(() => {
    getCurrentClient();
  }, []);

  console.log(endContract)

  return (
    <Container>
      <MenuBar />
      <Content>
        <ResponsiveContainer>
          <Header>
            <HeaderBack href="/recolocacao-profissional">
              <BackFullIcon />
            </HeaderBack>
            <HeaderInfo>
              <HeaderTitle>Edite os campos abaixo </HeaderTitle>
            </HeaderInfo>
          </Header>
          {loading
            ?
              (
              <LoadRow>
                  <LoadingIndicator
                    customStyle={{
                      width: 40,
                      height: 40,
                      color: theme.colors.secondary,
                      marginBottom: 12,
                    }}
                    size="large"
                  />
                </LoadRow> 

              )
            :
              (
                <>
                  <VacanciesContainer>

                    <InputContainer>
                      <SectionTitle>Dados do Pessoais</SectionTitle>
                      <FilesArea>
                          <ImageArea onClick={() => inputImageRef.current?.click()}>
                            <Image src={img ? img : EmptyImage} />
                            <PhotoIcon />
                          </ImageArea>
                          <ButtonPdf onClick={() => inputCurriculoRef.current?.click()}>Alterar currículo</ButtonPdf>
                          {curriculo && <FilesContent>
                            <FileName>{curriculo?.name}</FileName>
                            <CloseIcon onClick={() => setCurriculo(undefined)}/>
                          </FilesContent>}
                      </FilesArea>
                      <ImageInput
                        type="file"
                        accept="image/*"
                        ref={inputImageRef}
                        onChange={event => {
                          if (event && event.target?.files?.[0]) {
                            const file = event.target.files[0];

                            const reader = new FileReader();
                            reader.onloadend = () => {
                              setImageBase64(reader.result);
                            };
                            reader.readAsDataURL(file);
                            setImg(URL.createObjectURL(event.target.files[0]));
                          }
                        }}
                      />
                      <DocumentInput
                        type="file"
                        accept="application/pdf"
                        ref={inputCurriculoRef}
                        onChange={event => {
                          if (event && event.target?.files?.[0]) {
                            setCurriculo(event.target.files[0]);
                          }
                        }}
                      />
                      <InputsVancacieRow>
                        <InputsVancacie
                          customContainerSize="full"
                          customStyle={{ maxWidth: 'none' }}
                          customStyleLabel={{ backgroundColor: '#f9f9f9' }}
                          value={name}
                          setValue={setName}
                          placeholder="Nome*"
                          warning={warning.name}
                        />
                        <InputsVancacie
                          customContainerSize="half"
                          customStyleLabel={{ backgroundColor: '#f9f9f9' }}
                          value={mail}
                          setValue={setMail}
                          placeholder="Email*"
                          warning={warning.mail}
                        />
                        <InputsVancacie
                          customContainerSize="half"
                          customStyleLabel={{ backgroundColor: '#f9f9f9' }}
                          value={workplace}
                          setValue={setWorkplace}
                          placeholder="Local"
                          warning={warning.workplace}
                        />
                        <InputsVancacie
                          customContainerSize="half"
                          customStyleLabel={{ backgroundColor: '#f9f9f9' }}
                          value={cpf}
                          setValue={(e: any) => formatCPF(e)}
                          placeholder="CPF*"
                          warning={warning.cpf}
                        />
                        <InputsVancacie
                          customContainerSize="half"
                          customStyleLabel={{ backgroundColor: '#f9f9f9' }}
                          value={phone}
                          setValue={(e: any) => formatPhoneNumber(e)}
                          placeholder="Telefone*"
                          warning={warning.phone}
                          isPhone
                        />
                        <InputsVancacie
                          customContainerSize="half"
                          customStyleLabel={{ backgroundColor: '#f9f9f9' }}
                          value={birth}
                          setValue={(e: any) => formatDate(e)}
                          placeholder="Data de nascimento*"
                          warning={warning.birth}
                        />
                        <InputSelectComponent
                          customContainerSize="half"
                          customStyleLabel={{ backgroundColor: '#f9f9f9' }}
                          value={sex}
                          setValue={setSex}
                          placeholder="Sexo*"
                          warning={warning.sex}
                          selectData={CONST_SEXUALIDADE}
                        />

                        <InputsVancacie
                          customContainerSize="half"
                          customStyleLabel={{ backgroundColor: '#f9f9f9' }}
                          value={civilState}
                          setValue={setCivilState}
                          placeholder="Estado Civil*"
                          warning={warning.civilState}
                        />
                        <InputsVancacie
                          customContainerSize="half"
                          customStyleLabel={{ backgroundColor: '#f9f9f9' }}
                          value={childrens}
                          setValue={setChildrens}
                          placeholder="Numero de filhos"
                          type="number"
                          warning={warning.childrens}
                        />
                      </InputsVancacieRow>
                    </InputContainer>

                    <InputContainer>
                      <SectionTitle>Dados de Profissionais</SectionTitle>
                      <InputsContactRow>
                        <InputSelectComponent
                          customContainerSize="tird"
                          customStyleLabel={{ backgroundColor: '#f9f9f9' }}
                          value={status}
                          setValue={setStatus}
                          placeholder="Status*"
                          warning={warning.status}
                          selectData={CONST_STATUS}
                        />
                        <InputSelectComponent
                          customContainerSize="tird"
                          customStyleLabel={{ backgroundColor: '#f9f9f9' }}
                          value={occupation}
                          setValue={setOccupation}
                          placeholder="Cargo*"
                          warning={warning.occupation}
                          selectData={CONST_OCCUPATION}
                        />
                        <InputSelectComponent
                          customContainerSize="tird"
                          customStyleLabel={{ backgroundColor: '#f9f9f9' }}
                          value={kambamStep}
                          setValue={setKambamStep}
                          placeholder="Passo no kambam"
                          selectData={CONST_PERSONAL_CLIENT_KAMBAM_STEP}
                          warning={warning.kambamStep}
                        />
                        <InputsVancacie
                          customContainerSize="half"
                          customStyleLabel={{ backgroundColor: '#f9f9f9' }}
                          value={budget}
                          setValue={setBudget}
                          placeholder="Salário*"
                          warning={warning.budget}
                        />
                        <InputSelectComponent
                          customContainerSize="half"
                          customStyleLabel={{ backgroundColor: '#f9f9f9' }}
                          value={clientType}
                          setValue={setClientType}
                          placeholder="Tipo do cliente"
                          selectData={CONST_CLIENT_TYPE}
                          warning={warning.clientType}
                        />
                        <InputsVancacie
                          customContainerSize="half"
                          customStyleLabel={{ backgroundColor: '#f9f9f9' }}
                          value={startContract}
                          setValue={formatStartDate}
                          placeholder="Data inicio contrato"
                          warning={warning.budget}
                        />
                        <InputsVancacie
                          customContainerSize="half"
                          customStyleLabel={{ backgroundColor: '#f9f9f9' }}
                          value={endContract}
                          setValue={formatEndDate}
                          placeholder="Data fim de contrato*"
                          warning={warning.budget}
                        />
                        <InputsVancacie
                          customContainerSize="full"
                          customStyle={{ maxWidth: 'none' }}
                          customStyleLabel={{ backgroundColor: '#f9f9f9' }}
                          value={keyWord}
                          setValue={setKeyWord}
                          placeholder="Palavras chaves">
                          <ButtonAddKeyWord
                            onClick={() => {
                              let current = keyWordList ? keyWordList : [];
                              if (keyWord && keyWord !== '') current.push(keyWord);
                              setKeyWord('');
                              setKeyWordList(current);
                            }}>
                            <AddIcon />
                          </ButtonAddKeyWord>
                        </InputsVancacie>
                        {keyWordList && keyWordList?.length > 0 && (
                          <KeyWordsArea>
                            {keyWordList.map((e, i) => (
                              <KeyWord key={i}>{e}</KeyWord>
                            ))}
                          </KeyWordsArea>
                        )}
                        <InputsContactRow>
                          <SectionTitle style={{ margin: 0 }}>
                            Dados Trabalhísticos
                          </SectionTitle>
                          <InputsVancacie
                            customContainerSize="half"
                            customStyleLabel={{ backgroundColor: '#f9f9f9' }}
                            value={replacementCompany}
                            setValue={setReplacementCompany}
                            placeholder="Empresa substituição"
                          />
                          <InputsVancacie
                            customContainerSize="half"
                            customStyleLabel={{ backgroundColor: '#f9f9f9' }}
                            value={replacementSalary}
                            setValue={setReplacementSalary}
                            placeholder="Salário substituição"
                          />
                          <InputsVancacie
                            customContainerSize="half"
                            customStyleLabel={{ backgroundColor: '#f9f9f9' }}
                            value={replacementOffice}
                            setValue={setReplacementOffice}
                            placeholder="Ocupação substituição"
                          />
                          <InputsVancacie
                            customContainerSize="half"
                            customStyleLabel={{ backgroundColor: '#f9f9f9' }}
                            value={replacementDate}
                            setValue={(e: any) => formatDateReplacement(e)}
                            placeholder="Data para substituição"
                          />
                          <InputsVancacie
                            customContainerSize="full"
                            customStyleLabel={{ backgroundColor: '#f9f9f9' }}
                            value={conditionsMove}
                            setValue={setConditionMove}
                            placeholder="Condições para mudança"
                          />
                        </InputsContactRow>
                      </InputsContactRow>
                    </InputContainer>
                  </VacanciesContainer>
                  <ButtonRow>
                  <ButtonAddVancacie
                    title="Adicionar"
                    callback={() =>
                      handleUpdateVancancie(
                        curriculo,
                        imageBase64,
                        name,
                        mail,
                        workplace,
                        budget,
                        cpf,
                        phone,
                        birth,
                        status,
                        occupation,
                        sex,
                        clientType,
                        kambamStep,
                        childrens,
                        civilState,
                        keyWordList || [],
                        conditionsMove,
                        replacementDate,
                        replacementOffice,
                        replacementCompany,
                        replacementSalary,
                        startContract,
                        endContract
                      )                     
                    }
                  />
                  </ButtonRow>
                </>
              )
          }

          
        </ResponsiveContainer>
      </Content>
    </Container>
  );
};

export default EditPersonalClient;
