import styled from 'styled-components';

export const Container = styled.button`
   flex: 1;
   display: flex;
   align-items: center;
   justify-content: center;
   background-color: ${({theme}) => theme.colors.secondaryDark};
   opacity: ${({disabled}) => disabled && 0.5};
   width: 100%;
   padding: 10px 0;
   color: ${({theme}) => theme.colors.white};
   font-family: ${({theme}) => theme.fontFamily.poppins.semiBold};
   border-radius: 5px;
   min-width: 276px;
   transition: background-color 350ms ease;
   cursor: ${({disabled}) => !disabled && 'pointer'};
   &:hover {
      background-color: ${({theme}) => theme.colors.secondary};
   }
`;