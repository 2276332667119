import React from 'react';

import {
  ActionsRow,
  Amount,
  AmountIcon,
  ButtonComp,
  CardColumn,
  CardRow,
  CardRowForSvg,
  Code,
  Contact,
  ContactSvgRow,
  Content,
  CpfIcon,
  Description,
  EditIcon,
  HeaderRow,
  InfoArea,
  InfoSvgRow,
  KeyWord,
  KeyWordsArea,
  LoadingIndicator,
  LoadRow,
  MailIcon,
  MatchArea,
  MatchCard,
  MatchContainer,
  MatchJob,
  MatchName,
  Modal,
  MoneyIcon,
  Name,
  Occupation,
  OccupationIcon,
  PhoneIcon,
  PinIcon,
  RowTitle,
  Salary,
  SeeMoreButton,
  SeeMoreLink,
  TrashIcon,
  UserIcon,
  UserImg,
} from './styles';

import typ from './index.d';
import { VancancieCRUD } from '../../../services/requests/vancacies';
import { toast } from 'react-toastify';
import {
  formatarPhone,
  formatCPF,
  limitarString,
} from '../../../utils/convert';
import { ConsultantImg } from '../../cards/user-info/styles';
import { useNavigate } from 'react-router-dom';
import { MatchCRUD } from '../../../services/requests/matchs';
import MatchCRUDProps from '../../../services/requests/matchs/index.d';

const matchCRUD = new MatchCRUD();

const VancancieModal: React.FC<typ.Props> = ({
  onClose,
  vancacie,
  deleteCallback,
}) => {
  const navigate = useNavigate();
  
  const [matchs, setMatchs] = React.useState<MatchCRUDProps.readReturn>();
  const [loading, setLoading] = React.useState(false);

  const getMatchs = React.useCallback(async (page?: number) => {
    setLoading(true);
    try {
      const { data, status, error } = await matchCRUD.read({
        page,
        per_page: 3,
        vacancy_id: Number(vancacie?.id),
      });

      if (error) {
        throw new Error(error.message.description);
      }

      if (status === 200 && data) {
        setMatchs(data);
      }
    } catch (e) {
      toast('Ocorreu um erro nos matchs.')
    } finally {
      setLoading(false)
    }
  }, []);

  React.useEffect(() => {
    getMatchs();
  }, []);

  return (
    <Modal modalTitle="Vaga" onClose={onClose} hideButton maxWidth={576}>
      <HeaderRow>
        <Code>Codigo da Vaga: {vancacie?.id}</Code>
        <ActionsRow>
          <ButtonComp onClick={() => navigate(`/editar-vaga/${vancacie?.id}`)}>
            <EditIcon />
          </ButtonComp>
          <ButtonComp onClick={() => deleteCallback()}>
            <TrashIcon />
          </ButtonComp>
        </ActionsRow>
      </HeaderRow>
      <Content>
        <InfoArea>
          <Name>{vancacie?.name}</Name>
          <Occupation>{vancacie?.occupation}</Occupation>
          <Description></Description>
          <InfoSvgRow>
            <RowTitle>Dados da vaga</RowTitle>
            <SeeMoreLink href={`/visualizar-vaga/${vancacie?.id}`}>
              Ver mais
            </SeeMoreLink>
          </InfoSvgRow>
          <InfoSvgRow>
            <AmountIcon />
            <Amount>{vancacie?.quantity} vagas</Amount>
          </InfoSvgRow>
          <InfoSvgRow>
            <MoneyIcon />
            <Salary>R$ {vancacie?.budget ? vancacie?.budget : '-'}</Salary>
          </InfoSvgRow>
          <ContactSvgRow>
            <PinIcon />
            <Contact>{vancacie?.workplace ? vancacie?.workplace : 'Não informado'}</Contact>
          </ContactSvgRow>
          <InfoSvgRow>
            <RowTitle>Dados de Contato</RowTitle>
            <SeeMoreLink href={`/visualizar-vaga/${vancacie?.id}`}>
              Ver mais
            </SeeMoreLink>
          </InfoSvgRow>
          {
            vancacie?.contact_name || vancacie?.email || vancacie?.contact_phone 
            ?
              <>
                {vancacie?.contact_name && (
                  <ContactSvgRow>
                    <UserIcon />
                    <Contact>{vancacie?.contact_name}</Contact>
                  </ContactSvgRow>
                )}
                {vancacie?.email && (
                  <ContactSvgRow>
                    <MailIcon />
                    <Contact>{limitarString(vancacie.email, 28)}</Contact>
                  </ContactSvgRow>
                )}
                {vancacie?.contact_company && (
                  <ContactSvgRow>
                    <OccupationIcon />
                    <Contact>{limitarString(vancacie.contact_company, 28)}</Contact>
                  </ContactSvgRow>
                )}
                {vancacie?.contact_phone && (
                  <ContactSvgRow>
                    <PhoneIcon />
                    <Contact>{formatarPhone(vancacie?.contact_phone)}</Contact>
                  </ContactSvgRow>
                )}
              </>
            : <Contact>Sem informações...</Contact>
          }
          
          {vancacie?.key_words && vancacie?.key_words.length > 0 && (
            <KeyWordsArea>
              {vancacie.key_words.map((e, i) => (
                <KeyWord key={e}>{e}</KeyWord>
              ))}
            </KeyWordsArea>
          )}
        </InfoArea>
        <MatchArea>
          {loading
            ?
            <LoadRow>
              <LoadingIndicator />
            </LoadRow>
            :
              <>
                {matchs?.VacancyMatch ? (
                  <>
                    <MatchContainer>
                      {matchs?.VacancyMatch &&
                        matchs?.VacancyMatch.length > 0 &&
                        matchs?.VacancyMatch.map(e => (
                          <MatchCard key={e.id}>
                            <CardRowForSvg>
                              <UserImg
                                src={
                                  e.personal_client_relation.image ||
                                  require('../../../assets/temp/temp-user.png')
                                }
                              />
                              <CardColumn>
                                <MatchName>
                                  {limitarString(e.personal_client_relation.name, 21)}
                                </MatchName>
                                <MatchJob>
                                  {limitarString(
                                    e.personal_client_relation.occupation,
                                    17,
                                  )}
                                </MatchJob>
                              </CardColumn>
                            </CardRowForSvg>
                            <CardRow>
                              <CardRowForSvg>
                                <CpfIcon />
                                <MatchName>
                                  {formatCPF(e.personal_client_relation.cpf)}
                                </MatchName>
                              </CardRowForSvg>
                              <ConsultantImg
                                src={
                                  e.advisor_relation.image ||
                                  require('../../../assets/temp/temp-user.png')
                                }
                              />
                            </CardRow>
                          </MatchCard>
                        ))}
                    </MatchContainer>
                    <SeeMoreButton
                      title="Ver todos os matchs"
                      callback={() => navigate(`/matchs/${vancacie?.id}`)}
                    />
                  </>
                ) : null}
              </>
          }
        </MatchArea>
      </Content>
    </Modal>
  );
};

export default VancancieModal;
