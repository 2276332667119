import React, { LegacyRef } from 'react';

import typ from './index.d';
import {
  DocumentArea,
  DocumentButton,
  DocumentIcon,
  DocumentInput,
  FileName,
  InputsRow,
  Modal,
  PhotoIcon,
} from './styles';
import { TitleSection } from '../../../modal/styles';
import InputComponent from '../../../input';
import EmptyImage from '../../../assets/images/empty-image.png';
import { toast } from 'react-toastify';
import { CorporateClientCRUD } from '../../../../services/requests/corporate-client';

const corporateClietCrud = new CorporateClientCRUD();

const RecrutamentManageModal: React.FC<typ.Props> = ({
  onClose,
  refreshData,
  client,
}) => {
  // Refs
  const inputCurriculoRef = React.useRef<HTMLInputElement>(null);

  // States
  const [name, setName] = React.useState(client?.name || '');
  const [mail, setMail] = React.useState(client?.email || '');
  const [local, setLocal] = React.useState(client?.local || '');
  const [cnpj, setCnpj] = React.useState(client?.cnpj || '');
  const [description, setDescription] = React.useState(
    client?.description || '',
  );
  const [validate, setValidade] = React.useState(
    client?.contract_validate || '',
  );
  // const [file, setFile] = React.useState<File>();

  const [warn, setWarn] = React.useState({
    name: false,
    mail: false,
    local: false,
    // cnpj: false,
    description: false,
    validate: false,
  });

  const formatCNPJ = (value: string) => {
    if (value.length > 18) return; // CNPJ formatado: 00.000.000/0000-00
    value = value.replace(/\D/g, ''); // Remove todos os caracteres não numéricos
    value = value.replace(/(\d{2})(\d)/, '$1.$2'); // Adiciona o primeiro ponto
    value = value.replace(/(\d{3})(\d)/, '$1.$2'); // Adiciona o segundo ponto
    value = value.replace(/(\d{3})(\d)/, '$1/$2'); // Adiciona a barra
    value = value.replace(/(\d{4})(\d{1,2})$/, '$1-$2'); // Adiciona o hífen antes dos últimos dois dígitos
    setCnpj(value);
  };

  const formatDate = (value: string) => {
    if (value.length > 10) return; // Data formatada: DD/MM/AAAA
    value = value.replace(/\D/g, ''); // Remove todos os caracteres não numéricos
    value = value.replace(/(\d{2})(\d)/, '$1/$2'); // Adiciona a primeira barra
    value = value.replace(/(\d{2})(\d)/, '$1/$2'); // Adiciona a segunda barra
    setValidade(value);
  };

  // Callbacks
  const handleCreateCorporate = React.useCallback(
    async (
      name: string,
      mail: string,
      local: string,
      cnpj: string,
      description: string,
      validate: string,
    ) => {
      try {
        if (!name && !mail && !local && !cnpj && !description && !validate) {
          setWarn(e => ({
            ...e,
            name: true,
            mail: true,
            local: true,
            // cnpj: true,
            description: true,
            validate: true,
          }));
          toast('Digite os campos corretamente.');
          return;
        }
        if (!name) {
          setWarn(e => ({ ...e, name: true }));
          toast('Digite o nome.');
          return;
        }
        if (!mail) {
          setWarn(e => ({ ...e, mail: true }));
          toast('Digite o email.');
          return;
        }
        if (!mail.includes('@')) {
          toast('Digite o email corretamente');
          setWarn(e => ({ ...e, mail: true }));
          return;
        }
        if (!local) {
          setWarn(e => ({ ...e, local: true }));
          toast('Digite o local.');
          return;
        }
        // if (!cnpj) {
        //   setWarn(e => ({ ...e, cnpj: true }));
        //   toast('Digite o cpnj.');
        //   return;
        // }

        if (!description) {
          setWarn(e => ({ ...e, description: true }));
          toast('Digite a descrição.');
          return;
        }
        if (!validate) {
          setWarn(e => ({ ...e, validate: true }));
          toast('Digite a validade do contrato.');
          return;
        }

        const { data, status, error } = await corporateClietCrud.create({
          name,
          email: mail,
          local,
          description,
          cnpj: cnpj.replace(/\D/g, ''),
          contract_validate: validate,
        });

        if (error) throw new Error(error.message.description);

        if (status === 200) {
          toast('Cliente criado com sucesso.');
          onClose();
          refreshData();
        }
      } catch (e) {
        alert(e);
      }
    },
    [],
  );

  // Callbacks
  const handleEditCorporateClient = React.useCallback(
    async (
      name: string,
      mail: string,
      local: string,
      cnpj: string,
      description: string,
      validate: string,
    ) => {
      try {
        if (!name && !mail && !local && !cnpj && !description && !validate) {
          setWarn(e => ({
            ...e,
            name: true,
            mail: true,
            local: true,
            // cnpj: true,
            description: true,
            validate: true,
          }));
          toast('Digite os campos corretamente.');
          return;
        }
        if (!name) {
          setWarn(e => ({ ...e, name: true }));
          toast('Digite o nome.');
          return;
        }
        if (!mail) {
          setWarn(e => ({ ...e, mail: true }));
          toast('Digite o email.');
          return;
        }
        if (!mail.includes('@')) {
          toast('Digite o email corretamente');
          setWarn(e => ({ ...e, mail: true }));
          return;
        }
        if (!local) {
          setWarn(e => ({ ...e, local: true }));
          toast('Digite o local.');
          return;
        }
        // if (!cnpj) {
        //   setWarn(e => ({ ...e, cnpj: true }));
        //   toast('Digite o cpnj.');
        //   return;
        // }

        if (!description) {
          setWarn(e => ({ ...e, description: true }));
          toast('Digite a descrição.');
          return;
        }
        if (!validate) {
          setWarn(e => ({ ...e, validate: true }));
          toast('Digite a validade do contrato.');
          return;
        }

        const { data, status, error } = await corporateClietCrud.update({
          id: client?.id.toString() || '',
          name,
          email: mail,
          local,
          description,
          cnpj: cnpj.replace(/\D/g, ''),
          contract_validate: validate,
        });

        if (error) throw new Error(error.message.description);

        if (status === 200) {
          toast('Cliente editado com sucesso.');
          onClose();
          refreshData();
        }
      } catch (e) {
        alert(e);
      }
    },
    [],
  );

  return (
    <Modal
      modalTitle="Novo Cliente"
      onClose={onClose}
      buttonCallback={() => {
        if (!client) {
          handleCreateCorporate(name, mail, local, cnpj, description, validate);
          return;
        }
        handleEditCorporateClient(
          name,
          mail,
          local,
          cnpj,
          description,
          validate,
        );
      }}>
      <TitleSection style={{ marginTop: 0 }}>Dados Pessoais</TitleSection>
      <InputComponent
        placeholder="Nome"
        type="text"
        value={name}
        setValue={setName}
        customStyle={{ marginBottom: 14 }}
        warning={warn.name}
      />
      <InputComponent
        placeholder="Email"
        type="email"
        value={mail}
        setValue={setMail}
        customStyle={{ marginBottom: 14 }}
        warning={warn.mail}
      />

      <InputsRow style={{ marginBottom: 14 }}>
        <InputComponent
          placeholder="Cnpj"
          type="text"
          value={cnpj}
          setValue={formatCNPJ}
          customStyle={{ marginBottom: 0 }}
          customContainerSize="half"
          // warning={warn.cnpj}
        />
        <InputComponent
          placeholder="Local"
          type="text"
          value={local}
          setValue={setLocal}
          customStyle={{ marginBottom: 0 }}
          customContainerSize="half"
          warning={warn.local}
        />
      </InputsRow>
      <InputsRow>
        <InputComponent
          placeholder="Prazo de contrato"
          type="text"
          value={validate}
          setValue={formatDate}
          customStyle={{ marginBottom: 14 }}
          customContainerSize="full"
          warning={warn.validate}
        />
      </InputsRow>

      <InputComponent
        placeholder="Descrição"
        type="text"
        value={description}
        setValue={setDescription}
        customStyle={{ height: 106 }}
        warning={warn.description}
      />

      {/* 
        <InputsRow>
        <InputComponent
          placeholder="Prazo de contrato"
          type="text"
          value={validate}
          setValue={formatDate}
          customStyle={{ marginBottom: 0 }}
          customContainerSize="full"
          warning={warn.validate}
        />
        <DocumentButton onClick={() => inputCurriculoRef.current?.click()}>
          <DocumentIcon />
        </DocumentButton> 
      </InputsRow>

       {file && <FileName>{file.name}</FileName>} 

      <TitleSection>Dados Profissionais</TitleSection>
      <DocumentArea>
        <DocumentInput
          type="file"
          accept="application/pdf"
          ref={inputCurriculoRef}
          onChange={event => {
            if (event && event.target?.files?.[0]) {
              setFile(event.target.files[0]);
            }
          }}
        />
      </DocumentArea>
      <InputComponent
        placeholder="Nome da vaga"
        type="text"
        value={vacancyName}
        setValue={setVacancyName}
        customStyle={{ marginBottom: 14 }}
      />

      <InputsRow>
        <InputComponent
          placeholder="Número de vagas"
          type="text"
          value={vacancyQuantity}
          setValue={setVacancysQuantity}
          customStyle={{ marginBottom: 0 }}
          customContainerSize="half"
        />
        <InputComponent
          placeholder="Remuneração"
          type="text"
          value={vacancyBudget}
          setValue={setVacancyBudget}
          customStyle={{ marginBottom: 0 }}
          customContainerSize="half"
        /> 
      </InputsRow>*/}
    </Modal>
  );
};

export default RecrutamentManageModal;
